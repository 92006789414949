import React from 'react';

export const ArrowBackIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M12 9l-6 5.24.657.76 5.342-4.666L17.343 15l.657-.76z"
      />
    </defs>
    <use
      fillRule="evenodd"
      transform="matrix(0 1 1 0 0 0)"
      xlinkHref="#prefix__a"
    />
  </svg>
);
