import React from 'react';
import { st, classes } from './Container.st.css';

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {}

/**
 * Container
 *
 * Component for basic layout with `display: grid`
 */
export const Container: React.FC<ContainerProps> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={st(classes.root, {}, className)} {...rest}>
      {children}
    </div>
  );
};

Container.displayName = 'Container';
